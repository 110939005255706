<section class="section banner">
    <div [@bannerTrigger] class="container">
        <div class="section-box-banner">
            <div class="content">
                <div>
                    <h1>(site under construction)</h1>
                    <!-- <h1>{{"Banner.Pretitle" | translate}}</h1> -->
                </div>
                <div class='banner-title'>
                    <h2>Michael Fried</h2>
                    <h3>Full Stack Software Engineer</h3>
                </div>
                <div class='banner-description'>
                    <!-- <p [innerHTML]='"Banner.Description" | translate' class="mt-4"> -->

                    <p class="mt-4">
                        Software Development Engineer with extensive experience in Agile Kanban and traditional software
                        development methodologies. Skilled in taking software from requirements gathering through
                        design, development, and deployment to a fully functioning production environment. Proficient in
                        designing and developing backend web microservices using Kotlin, Java, and Spring for
                        high-volume production systems. Experienced in front-end technologies such as iOS and web
                        application development. Adept at creating comprehensive automated testing suites across various
                        languages, including unit, component, and integration tests. Highly skilled at rapidly learning
                        and applying new technologies.</p>
                </div>
            </div>
            <div class="div-btn-banner">
                <a (click)='analyticsService.sendAnalyticEvent("click_send_mail", "banner", "email")'
                    href="mailto:email@michaelfried.info" target="_black" class="main-btn">
                    {{"Banner.ActionBtn" | translate}}
                </a>
            </div>
        </div>
    </div>
</section>